<template>
  <div class="modal" :class="type">
    <div class="modal__container animated fadeIn" ref="modal"
         :style="{
                    'background': bg,
                    '--height': height,
                    '--width': width,
                    '--padding':padding
             }">
      <div class="m-header">
        <slot name="mHeader"></slot>
        <div class="close-modal" @click="closeModal()">
          <i class="ez-icon-clear"></i>
        </div>
      </div>
      <div class="m-body">
        <slot name="mBody" class="slot-body"></slot>
      </div>
      <div class="m-footer" v-if="footerVisible">
        <slot name="mFooter"></slot>
      </div>
      <div class="m-second-footer" v-if="secondFooter">
        <slot name="mSecondFooter"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",

  props: {
    close: { type: Function },
    footerVisible: { type: Boolean, default: true },
    secondFooter: { type: Boolean, default: true },
    type: { type: String, default: 'normal' },
    padding: { Type: String, default: '2.9375rem' },
    bg: String,
    height: String,
    width: String,
  },

  methods: {
    closeModal() {
      return this.close && this.close();
    }
  },

  mounted() {
    this.$hideModals();
  },
}
</script>

<style lang="scss" scoped>
.modal {
  background: rgba(color(bg-blue-dark), .6);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999999999999;


  &.normal {
    --height: 300px;
    --width: 550px;
    @include breakpoint-width-down(small) {
      padding: 0 .5rem;
      --width: 100%;
    }
  }

  &.delete,
  &.m-small {
    --height: 200px;
    --width: 350px;

    .m-header {
      display: none;
    }

    .m-body {
      border-radius: 3px 3px 0 0;
      border-top: 0;
      line-height: 25px;
      color: color(bg-black1);
      display: flex;
      align-items: center;
      justify-content: flex-start;

      * {
        font-weight: $font-bold;
      }
    }
  }

  &__container {
    border-radius: 3px;
    box-shadow: 0 10px 35px 5px rgba(color(bg-blue-dark), .4);
    display: flex;
    flex-direction: column;
    max-width: 90vw;
    min-width: var(--width);
    min-height: var(--height);
    width: var(--width);
    position: relative;

    & > .m-header {
      background: color(bg-white);
      border-bottom: 1px solid rgba(color(border), .2);
      border-radius: 3px 3px 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 6rem;
      padding: 0 1.25rem;

      .m-title {
        color: color(bg-gray-dark);
        font-weight: $font-bold;
        font-size: 1.5rem;
        text-transform: capitalize;

        & > span {
          color: inherit;
          font-weight: inherit;
          font-size: inherit;
          text-transform: inherit;
        }
      }

      .close-modal {
        cursor: pointer;
        font-size: 2rem;
      }
    }

    & > .m-body {
      background: color(bg-white);
      padding: var(--padding);
      flex: 1;

    }

    & > .m-footer {
      border-radius: 0 0 3px 3px;
      background: color(bg-white);
      border-top: 1px solid color(border);
      display: flex;
      align-items: center;
      padding: 0 20px;
      min-height: 5.875rem;
      max-height: 120px;
      justify-content: flex-end;

      .btn-actions {
        display: flex;
        gap: .7rem;
      }
    }
  }
}
</style>
